<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  mounted() {
    this.$router.push({name: 'SnsTimeline', params: {user_id: this.$route.meta.current_user.id}})
  }
}
</script>
